<template>
  <div>
    <el-dialog
      style="text-align: center;"
      title="更改管理员"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-button type="primary" size="default" @click="clear_user"
        >清空管理员</el-button
      >
      <div class="line" />
      <div class="body">
        <el-input
          v-model="form.phone"
          placeholder="请输入管理员手机号"
          style="margin-bottom: 30px;"
        />
        <el-button type="primary" size="default" @click="change_user"
          >更改管理员</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      input: '',
      form: {
        id: 1,
        phone: '',
        type: 1
      }
    }
  },
  methods: {
    async clear_user() {
      const { data: res } = await this.$http.post(
        '/admin/Enterprise/changeManager',
        this.form
      )
      if (res.errorCode == 200) {
        this.$message.success(res.message)
        this.dialogVisible = false
        this.$emit('getlist')
      }
    },
    async change_user() {
      const { data: res } = await this.$http.post(
        '/admin/Enterprise/changeManager',
        {
          ...this.form,
          type: 2
        }
      )
      if (res.errorCode == 200) {
        this.$message.success(res.message)
        this.dialogVisible = false
        this.$emit('getlist')
      }
    },
    handleClose(done) {
      this.form.phone = ''
      done()
    }
  }
}
</script>

<style lang="less" scoped>
.line {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  height: 1px;
  background-color: #ddd;
}
.body {
  padding: 0 30px;
}
</style>
